.Polaris-Icon { 
  display: inline-block;
}

.sync-spinner {
  position: absolute;
  left: -23px;
  top: 0px;
}

.sync-wrapper {
  position: relative;
}


.sync-li {
  display: block;
  height: 26px;
  position: relative;
}

.sync-li.syncing {
  list-style-type: none;
}

.card-wrapper {
  padding: 1.2em;
}

.card-wrapper > .title {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 10px;;
}

.progress-line {
  margin-bottom: 7px;
}

.underline {
  text-decoration: line-through;
  margin-bottom: 5px;
  display: block;
}

.call-to-action {
  color: #006e52;
  font-weight: 600;
  cursor: pointer;
}

.x-icon-wrapper {
  float: right;
  stroke: rgb(235, 235, 235);
  cursor: pointer;
}

.review-wrapper {
  margin-bottom: 20px;
}

.review-container {
  border: 1px solid #4C4CFF;
  height: 40px;
  width: 60%;
  min-width: 300px;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.star-ratings {
  width: 55%;
  margin-left: 10px;
}

.ratings-title {
  width: 45%;
}

.Polaris-Modal-CloseButton {
  background-color: white;
}

.rate-title {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

#PolarisTextField1{
  color: rgb(43, 43, 43);
}


.description {
  margin-bottom: 20px;
  color: black;
  font-weight: 500;
}

.submit-button {
  margin-top: 25px;
  text-align: center;
}

.Polaris-DisplayText--sizeSmall {
  font-weight: 500;
}

@media (max-width: 48.0525em) {
.Polaris-Modal-Dialog__Modal {
  top: 5%;
  bottom: 4%;
}
}

.rate-button {
  text-align: center;
  padding-bottom: 40px;;
}

.doing-sync {
  padding-left: 3px;
}

.Polaris-ProgressBar__Indicator {
  background-color: #006e52;
}

.Polaris-Modal-CloseButton {
  z-index: 1;
}

.waiting-spinner {
  display: flex;
}

.waiting-text {
  padding-left: 7px;
}

.subscription-wrapper {
  padding: 20px;
}

.days-left {
  margin-bottom: 6px;
}

.warranty-learn-more {
  float: right;
}

.warranty-learn-more .Polaris-Button__Text {
  display: contents;
}

.warranty-learn-more .Polaris-Button--primary {
  padding-right: 7px;
}

.warranty-learn-more {
  float: right;
  color: #006e52;
}

[data-portal-id="modal-portal-1"] .Polaris-Modal-CloseButton {display: none;}

.embedded-app-link-wrapper {
  display: flex;
  align-items: center;
}

.embedded-app-link-cta {
  margin-left: auto;
}

.embedded-app-buttons-wrapper {
  display: flex;
  margin-left: auto;
}

.embedded-app-buttons-wrapper > button:first-child {
  margin-right: 10px;
}

@media (max-width: 48.0525em) {
.Polaris-Modal-Dialog__Modal {
    bottom: 100px;
}
}

.dev-plan {
  padding: 20px;
  text-align: center;
  background-color: #d2f3d2;
}

.dev-plan-inner {
  font-weight: 600;
}



.status-tab .embedded-app-buttons-wrapper>button:first-child {
  margin-right: 10px;
  border: 2px solid #008060;
}


.status-tab .embedded-app-buttons-wrapper>button:first-child:hover {
  background: #00806011;
}


.status-tab .embedded-app-buttons-wrapper .Polaris-Button--plain:hover {
  text-decoration: none;
}

.status-tab .embedded-app-buttons-wrapper .Polaris-Button__Text {
  color: #008060;
}


.status-tab .features-status .Polaris-Card__Section {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  min-height: 136px;
}

.status-tab .Polaris-Card + .Polaris-Card {
  margin-top: 0rem;
}

.status-tab .Polaris-Card {
  margin-bottom: 6px;
  margin-right: 6px;
}


.status-tab .offers-wizard-wrapper {
  display: flex;
  align-items: center;
}

.status-tab .book-meeting-wrapper {
  display: flex;
  align-items: center;
}

.status-tab .offers-wizard-wrapper > button {
  margin-left: auto;
}

.status-tab .book-meeting-wrapper > .Polaris-Button {
  margin-left: auto;
}

@media (min-width: 720px) {

  .features-status .Polaris-SettingAction__Setting {
    width: max-content;
  }

}

.features-status .Polaris-SettingAction__Action {margin-top: 0;}

.wizard-meeting-wrapper {
  display: flex;
  margin-top: 10px;
}

.wizard-meeting-wrapper .Polaris-Card__Section {
  width: 100%;
}

.wizard-meeting-wrapper .Polaris-Card__Section + .Polaris-Card__Section {
  border-top: none;
}

.features-status-header {
  padding-left: 20px;
}

.wizard-meeting-status-header {
  padding-left: 20px;
}


.wizard-meeting-status-header .Polaris-Card__SectionHeader { padding-bottom: 0;}

.wizard-meeting-status-header.header-margin-top {
  margin-top: 10px;
}
.features-status .Polaris-Card__Section {
  padding-top: 3px;
}

.wizard-meeting-wrapper  .Polaris-Card__Section {
  padding-top: 3px;
}