.mix-and-match-products-wrapper {

}



.mm-save-btn { margin-left: 5px;}

.mm-locale-wrapper {
  padding: 0px 9px 12px 6px;
  margin: 9px 0px;
  border: 1px solid #dadada;
  border-radius: 5px;
  background-color: #f6f6f6;
}

.mm-discount-wrapper {
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.mm-collection-wrapper {
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.mm-more-settings {
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.mm-offer-location {
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.mm-offer-name {
  margin-top: 15px;
  margin-bottom: 4px;
}

span.mm-offer-name-tip {
  color: gray;
}

.mm-search-item {
  padding: 10px 7px;
  border-radius: 8px;
  margin-top: 1px;
  display: flex;
  place-items: center;
  position: relative;
  border: 1px solid #d6d6d6;
  cursor: pointer;
}

.mm-search-item:hover {
  background-color: #f1f1f1;
}

.mm-collection-title {

}

.mm-collection-count {
  margin-left: 3px;
}

.mm-selected-collection {
  padding: 10px;
  border: 1px solid #b9b9b9;
  margin-top: 5px;
  background-color: #eaeaea;
}

.mm-add-collection {
  margin-top: 15px;
  display: block;
}

.mm-selected-collection-qty {
  display: flex;
  align-items: center;
}

.mm-selected-collection-qty .remove-item {
  margin-right: 10px;
  margin-left: auto;
}

.mm-selected-collection-qty > div {
  width: 100%;
  margin-right: 13px;

}

.mm-selected-collection-name {
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 4px;
  display: block;

}

.mm-selected-collections-wrapper {
  border: 1px solid #dbdbdb;
  padding: 10px;
  margin-top: 5px;
}

.mm-config-title {
  margin-top: 7px;
  margin-bottom: 1px;
}

.mm-offer-locations-wrapper {
  display: grid;
  padding: 10px;
  border: 1px solid #dbdbdb;
  background-color: white;
}

.mm-selected-collections-wrapper-empty {
  /*margin-bottom: 18px;*/
}

.mm-create-offer-btn {
  margin-bottom: 10px;
  display: block;
}

.mix-and-matches-list-item {
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  padding: 9px;
  margin-top: -1px;

}

.mm-list-action-wrapper {
  margin-left: auto;
}

.mm-promo-page-link, .mm-item-short-code {
  margin-right: 12px;
}

.mm-item-enable-disable-btn {
  margin-right: 4px;

}

.mm-item-edit-btn {
  margin-right: 8px;
}

.mm-item-edit-btn button {
  width: 80px;
}


.mm-short-code-wrapper {
  height: 110px;
  display: flex;
  align-items: center;
  padding: 15px;
}

.mm-short-code-wrapper > code {
  text-align: center;
}

.copy-short-code {
  cursor: pointer;
  margin-right: 10px;
  top: 8px;
  left: 8px;
  position: absolute;
  display: none;
}

.view-mnm-item {
  cursor: pointer;
}

.mm-add-trigger-collection {
  margin-top: 3px;
  margin-bottom: 3px;

}

.mm-not-supported-wrapper {
  padding: 10px 10px;
}

.mm-not-supported-title {
  font-size: 15px;
}

.mm-not-supported-btn-wrapper {
  margin-top: 10px;
}

.mm-add-trigger-collection-item {
  padding: 10px;
  border: 1px solid #bbbbbb;
  display: flex;
  align-items: center;
  margin-top: -1px;
  background-color: #ececec;
}


span.mm-trigger-collection-remove {
  margin-left: auto;
}

.mm-add-trigger-collection-text { font-weight: 600; }

.mm-color-field-title {
  margin-top: 15px;
}