.sticky-atc-settings {
  padding: 15px 25px;
}

.sticky-atc-settings .margin-bottom-20 { margin-bottom: 20px;}

.sticky-atc-settings .select-label { }

.sticky-atc-settings  .select-lable-wrapper { margin-top: 8px; margin-bottom: 2px;         align-items: center;
};
.sticky-atc-settings  .select-lable-wrapper > .info-top {
  margin-left: 0px; color: gray; font-size: 12px};

.sticky-atc-settings .Polaris-Card { padding: 0px 20px 20px 20px;}


.sticky-atc-settings .Polaris-Card .chrome-picker { margin: 20px;}

.sticky-atc-settings .color-field-title {
  margin-top: 10px;
}

.sticky-atc-settings .save-button { position: fixed; bottom: 15px;}


.sticky-atc-settings .save-button > button {
  width: 140px;
  height: 50px;
}

.sticky-add-to-cart-css-editor { padding: 5px 20px 20px 20px;}

.sticky-add-to-cart-css-editor .save-button { position: fixed; bottom: 15px;}


.sticky-add-to-cart-css-editor  .save-button > button {
  width: 140px;
  height: 50px;
}

.sticky-atc-ticket-submit {
  margin-top: 10px;
}

.sticky-atc-support-form { padding: 10px 20px 20px 20px;}

.sticky-add-to-cart-dashboard { padding: 15px 20px 20px 20px}

.sticky-add-to-cart-dashboard .Polaris-Card { padding: 10px;}

.sticky-add-to-cart-dashboard .embedded-app-buttons-wrapper .Polaris-Button__Text {
  color: #008060;
  font-weight: 600;
}

.sticky-add-to-cart-stats {
  border: 1px solid #e2e6e9;
  height: 10rem;
  display: flex;
  flex-direction: row;
}

.sticky-add-to-cart-stats-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-right: 1px solid #e2e6e9;
  margin-right: -1px;

}

.sticky-add-to-cart-zoorix-banner-inner{
  display: flex;
  align-items: center;
}

.sticky-add-to-cart-zoorix-banner-text{
  color: #56524e;
  font-size: 15px;
}

.sticky-add-to-cart-zoorix-banner-text span{
  color: green;
}

.sticky-add-to-cart-zoorix-banner-text h3 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: bold;
}

.sticky-add-to-cart-stats-item .sticky-add-to-cart-stats-item-label {
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #637381;
  margin-bottom: 0.9rem;

}

.sticky-add-to-cart-stats-item .sticky-add-to-cart-stats-item-count {
  color: #212B36;
  font-size: 2.6rem;
  line-height: 3.1rem;
}

.sticky-add-to-cart-stats-wrapper .sticky-add-to-cart-stats-subtitle {
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #637381;
  margin-bottom: 2rem;
}
.sticky-add-to-cart-stats-wrapper .sticky-add-to-cart-stats-title {
  display: flex;
  flex-direction: row;
  color: #212B36;
  font-size: 1.6rem;
  line-height: 1.9rem;
  align-items: center;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sticky-add-to-cart-stats-title-icon {
  padding: 7px;
  color: black;
}

.sticky-add-to-cart-stats-spinner {
  min-height: 32px;
}

.sticky-atc-faq {
  padding: 20px;
}

.sticky-add-to-cart-dashboard .embedded-app-buttons-wrapper>button:first-child {
  margin-right: 10px;
  border: 2px solid #008060;
}


.sticky-add-to-cart-dashboard .embedded-app-buttons-wrapper>button:first-child:hover {
  background: #00806011;
}


.sticky-add-to-cart-dashboard .embedded-app-buttons-wrapper .Polaris-Button--plain:hover {
  text-decoration: none;
}

.sticky-add-to-cart-zoorix-promo .Polaris-Badge--statusNew {
  //background-color: rgb(29, 155, 240);
  background-color:#42a089;
  color: white;
  font-weight: 500;
  border: none;
  padding: 2px;
  margin-left: 3px;
  border-radius: 50%;
  width: 8px;
  justify-content: center;
  height: 8px;
  font-size: 6px;
  position: absolute;
}


.zrx-sticky-atc-shake:not(:hover) {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: zrxShake;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation: 1.25s ease-in infinite 2 zrxShake;
  animation-iteration-count: infinite;
}

@keyframes zrxShake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  2.5% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  5% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  10% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  12.5% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  15% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  17.5% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  20% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  22.5% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  25% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  50% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  60% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  80% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  90% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

.maintanage-message {
  max-width: 600px;
  background-color: #e1e1e1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 40px;

  .maintanage-message-header {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .maintanage-message-contact-us {
    margin-top: 25px;
  }
}

.sticky-add-to-cart-zoorix-banner {

  display: flex;
  flex-direction: column;

  .dashboard-status-partner-cta {
    display: inline-block;
    border-radius: 6px;
    font-size: 12px;
    color: #868383;
    font-weight: 500;
    cursor: pointer;
    padding: 1px 10px;
    margin-top: 8px;
    border: 1px solid #d8d8d8;
  }

  .sticky-add-to-cart-zoorix-banner-icon img {
    border-radius: 8px;
  }

  .sticky-add-to-cart-zoorix-banner-icon figure {
    margin: 0 15px 0 0;
  }

  .sticky-add-to-cart-zoorix-banner-text-wrapper {
    line-height: 20px;
    .sticky-add-to-cart-zoorix-app-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 7px;
    }
    .sticky-add-to-cart-zoorix-app-desc {
      color: #56524e;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;

      .sticky-add-to-cart-zoorix-app-features {
        margin-top: 3px;
      }

      .dashboard-status-partner-star {
        margin: 0 3px;
      }
    }
    .dashboard-status-partner-star path {
      fill: green;
    }
  }
}

.sticky-add-to-cart-partner {

  background-color: var(--p-surface);
  box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  box-shadow: var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15));
  outline: 0.1rem solid transparent;
  padding: 10px 20px 20px 20px;
  margin-top: 12px;
  border-radius: var(--p-border-radius-wide,3px);
  
  .sticky-add-to-cart-partner-section-title {
    display: flex;
    flex-direction: row;
    color: #212B36;
    font-size: 1.6rem;
    line-height: 1.9rem;
    align-items: center;
    font-weight: 600;
    margin-bottom: 11px;
    margin-top: 8px;
  } 

  .sticky-add-to-cart-partner-section-subtitle {
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #637381;
    margin-bottom: 20px;
  }

}