.sidebar-item {
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 400;
    padding-top: 1px;
    padding-bottom: 1px;
}

.sidebar-item.selected {
    font-weight: 600;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
    padding-left: 4px;
    margin-left: -6px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: rgb(246, 246, 246);
}

.products-wrapper {
    display: flex;
    min-height: 300px;
}

.slider {
    width: 100px;
    height: auto;
    border-right: 1px solid rgb(232, 232, 232);
    margin-right: 3px;
    padding-right: 10px;
    padding-left: 15px;
    padding-top: 10px;
}

.slider.settings {
    height: auto;
}

.products-item {
    width: 100%;
}

.products-item.bundle-products-item .Polaris-Card__Section {
    padding: 5px 20px 20px 10px;
}

.products-item.warranty-products-item .Polaris-Card__Section {
    padding: 5px 20px 20px 10px;
}


#settings-panel>.Polaris-Card__Section {
    padding-left: 0;
    padding-top: 2px;
}

#products-panel>.Polaris-Card__Section {
    padding-left: 0;
    padding-top: 2px;
}

.spinner-wrapper.product-mapping-spinner {
    align-self: center;
}