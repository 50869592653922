.bundle-product-image.vd-item-image {
    margin-right:15px;
}

.delete-tier-button-wrapper {
    margin-top: 8px;
    text-align: right;
}

.mark-popular-remove-wrapper {
    margin-top: 10px;
}

.vd-item {
    display: flex;
    align-items: center;
    border: 1px solid #d6d6d6;
    padding: 10px;
    margin-top: -1px;
}

.vd-items-wrapper {
    margin-top: 10px;
    min-height: 250px;

}

.vd-edit-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.vd-item-remove {
    margin-left:10px;
    cursor: pointer;
}

.vd-short-code-wrapper {
    margin-right: 8px;
}

.vd-offer-name {
    margin-right:10px;
    font-weight: 600;
}

.vd-item-view {
    margin-left: 10px;
    margin-right: 5px;
    cursor: pointer;
}

.vd-display-group-wrapper {
    padding: 15px;
    border: 1px solid #d6d6d6;
    margin-top: 5px;

}

.vd-display-item {
    display: flex;
    padding: 15px;
    border: 1px solid #dedede;
    margin-top: -1px;
}

.vd-remove-display-item {
    margin-left: auto;
}

.vd-display-item-image {
    width: 25px;
    height: 25px;
    background-size: contain;
    margin-right: 5px;
}

.vd-product-to-show-on-title {
    display: flex;
    align-items: center;
}

.vd-display-items-wrapper {
    margin-top: 5px;
}

.vd-enable-disable-wrapper {
    margin-right: 5px;
}

.vd-type-amount-wrapper {
    display: flex;
}

.vd-type-amount-wrapper > div:first-child {
    width: 100%;
}

.vd-type-amount-wrapper > div:nth-child(2) {
    min-width: 150px;
    margin-left: 10px;
}

.vd-tier-wrapper {
    padding: 10px;
    border: 1px solid #d0d0d0;
}


.delete-tier-button-wrapper .Polaris-Button--plain svg {
    fill: black;
}

.delete-tier-button-wrapper button { outline: none; }

.pre-defined-variants-box {
    padding: 0px 20px 20px 20px;
    border: 1px solid #dddddd;
    margin: 10px 30px 20px 30px;
    display: flex;
    align-items: flex-end;
}

.pre-defined-variants-box > div:first-child {
    width: 85%;
    margin-right: 5px;

}


.pre-defined-variants-box > div:nth-child(2) {
    width: 15%;
    margin-right: 5px;

}

.pre-defined-variants-box > button {
    margin-left: auto;
    max-width: 42px;
    max-height: 45px;
    border: none;
}

.vd-short-code-copy {
    margin-top: 10px;
    cursor: pointer;
}

